<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon> <span class="primary--text">{{$t('menu.caseLetterType')}}</span>
      <v-spacer></v-spacer>
      <v-btn 
        class="primary mt-2" 
        medium
        rounded
        @click.stop="dialog = true"
      >
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> {{$t('button.newLetterType')}}
      </v-btn>
    </v-card-title>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left>{{icons.mdiAccount}}</v-icon> {{ dialogTitle }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.letterTypeNameKh')"
                  :append-icon="icons.mdiAccount"
                  dense
                  v-model="caseLetterType.letterNameKh"
                  :rules="rule.requiredField()"
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.letterTypeNameEn')"
                  :append-icon="icons.mdiAccount"
                  v-model="caseLetterType.letterNameEn"
                  dense
                ></v-text-field>  
              </v-col>          
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('text.letterTypeCode')"
                  :append-icon="icons.mdiAccount"
                  v-model="caseLetterType.letterTypeCode"
                  dense
                ></v-text-field> 

              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="caseLetterType.letterType"                                             
                  :label="$t('select.letterType')"
                  :items="letterTypeList"
                  item-value="{}"
                  :rules="rule.requiredField()"
                  hide-details
                  dense
                 >
                    <template slot="selection" slot-scope="data">
                      <span v-if="$t('lang') == 'KH'">
                         {{data.item.typeNameKh}}
                      </span>
                      <span v-else>{{data.item.typeNameEn}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span v-if="$t('lang') == 'KH'">
                        {{data.item.typeNameKh}}
                      </span>
                      <span v-else>{{data.item.typeNameEn}}</span>
                    </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="save"
          >
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn
            class="secondary"
            @click="dialog = false"
          >
            <v-icon left>{{icons.mdiCancel}}</v-icon> {{$t('button.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-select
            :items="letterTypeList"
            item-value="id"
            :label="$t('select.letterType')"
            outlined
            dense
            rounded
            @change="changeLetterType($event)"
          >
            <template v-slot:selection="data">
              <td v-if="$t('lang') == 'KH'">
                {{data.item.typeNameKh}}
              </td>
              <td v-else>
                {{data.item.typeNameEn}}
              </td>               
            </template>
            <template v-slot:item="data">
              <td v-if="$t('lang') == 'KH'">
                {{data.item.typeNameKh}}
              </td>
              <td v-else>
                {{data.item.typeNameEn}}
              </td>                  
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="4"                  
        >
          <v-text-field
            :prepend-inner-icon="icons.mdiMagnify"
            rounded
            single-line
            outlined
            dense
            v-model="search"
            label="ស្វែករកតាមឈ្មោះ"
          ></v-text-field>
        </v-col>
      </v-row>
        <v-data-table
          :headers="headers"
          :items="caseLetterTypeList"
          :search="search"
        >
          <template v-slot:[`item.letterType`]="{ item }">
            <td v-if="$t('lang') == 'KH'">{{item.letterType.typeNameKh}}</td>
            <td v-else>{{item.letterType.typeNameEn}}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="editItem(item)">
              {{icons.mdiPencil}}
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              {{icons.mdiDelete}}
            </v-icon>
          </template>
        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiDelete, mdiContentSave, mdiCancel, mdiMagnify, mdiPlusThick } from '@mdi/js';
import SystemService from '@/services/service.system';
import Rule from '@/plugins/rules.js';

export default {
  name: 'case-letter-type',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiMagnify,
        mdiPlusThick,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name (KH)', value: 'letterNameKh' },
        { text: 'Name (EN) ', value: 'letterNameEn' },
        { text: 'Letter Type', value: 'letterType' },
        { text: 'ACTION', value: 'actions', sortable: false },
      ],
      rule: [],
      letterTypeList: [],
      caseLetterTypeList: [],
      caseLetterType: {},
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1,
    };
  },
  created() {
    this.rule = Rule;
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('caption.newLetterType') : this.$t('caption.editLetterType');
    },
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.chosenItems.push(item);
        this.$emit('input', item);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    SystemService.getCaseLetterTypeAll().then(response => {
      this.caseLetterTypeList = response.data;
    });

    SystemService.getLetterTypeAll().then(response => {
      this.letterTypeList = response.data;
    });
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.caseLetterTypeList.indexOf(item);
      this.caseLetterType = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.$confirm({
        message: 'Do you want to delete ' + item.letterNameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            SystemService.deleteCaseLetterTypeById(item.id).then(
              () => {
                const index = this.caseLetterTypeList.indexOf(item);
                this.caseLetterTypeList.splice(index, 1);
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted',
                });
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response ? error.response.data : 'An error occurs',
                });
              },
            );
          }
        },
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      SystemService.insertCaseLetterType(this.caseLetterType).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedCaseLetterType'),
              });

              this.caseLetterTypeList.push(response.data);
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedCaseLetterType'),
              });
              Object.assign(this.caseLetterTypeList[this.editedIndex], response.data);
            }
            this.dialog = false;
          }
        },
        error => {
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: error.response ? error.response.data : 'An error occurs',
          });
        },
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.caseLetterType = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    changeLetterType: function(letterTypeId) {
      SystemService.getCaseLetterTypeByLetterTypeId(letterTypeId).then(response => {
        this.caseLetterTypeList = response.data;
      });
    },
  },
};
</script>
